export function encode64(data) {
  return btoa(data);
}
export function decode64(data) {
  return new TextDecoder().decode(urlB64ToUint8Array(data));
}

export function parseNumber(str) {
  console.log(str);
  if (str.includes(",") && str.includes(".")) {
      if (str.indexOf(",") < str.indexOf(".")) {
      str = str.replace(/,/g, '');
    }
    else {
      str = str.replace(/\./g, '');
      str = str.replace(',', '.');
    }
  } else if (str.includes(",")) {
    str = str.replace(/\./g, '').replace(',', '.');
  } else {
    str = str.replace(/,/g, '');
  }
  return parseFloat(str);
}

function urlB64ToUint8Array(base64String) {
  const padding = String("=").repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i)
    outputArray[i] = rawData.charCodeAt(i);

  return outputArray;
}

export function jsonToCsv(items, csvHeaders, filename) {
  const JStoExcelDate = (date) => {
    const [day, month, year] = date.split(" ");
    return [year, month, day].join("-");
  };

  const csvString = [
    ...items.map((item) => {
      let temp = [];
      csvHeaders.forEach(({ key, dataType }) => {
        let valor = (
          item[key] != null ? String(item[key]) : item[key] || "\u0020"
        ).replace("\r\n", "");
        if (dataType == "date")
          valor = JStoExcelDate(parseDate(valor, false, false));
        else if (dataType == "number") valor = valor.replace(".", ",");
        else if (dataType == "html")
          valor = valor.replaceAll(/<[^>]*>/g, "").replaceAll(/;/g, "");
        temp.push(valor);
      });
      return temp;
    }),
  ];

  const csvFile = new File(
    [
      "\ufeff",
      [csvHeaders.map((h) => h.alias || h.key), ...csvString]
        .map((e) => e.join(";"))
        .join("\n"),
    ],
    `${filename}.csv`,
    { type: "text/csv;charset=utf-8;" }
  );

  downloadFile(csvFile);
}

export function humanFileSize(bytes, dp = 1) {
  const thresh = 1000;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

export function parseDate(date, timestamp = false, mes = true, hora = false) {
  const isDate = function (date) {
    return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
  };

  if (date == null || date == undefined || date == "") return "";
  let fecha;

  if (isDate(date)) {
    fecha = new Date(date);
  } else if (!isNaN(Number(date))) fecha = new Date(Number(date));
  else fecha = new Date(String(date).replaceAll(/\\/g, "").split(" ")[0]);

  if (timestamp) {
    fecha.setHours(0, 0, 0, 0);
    return fecha.getTime();
  }

  const MESES = [
    "ENE",
    "FEB",
    "MAR",
    "ABR",
    "MAY",
    "JUN",
    "JUL",
    "AGO",
    "SEP",
    "OCT",
    "NOV",
    "DIC",
  ];
  let day = `0${fecha.getDate()}`.slice(-2);
  let month = mes
    ? MESES[fecha.getMonth()]
    : `0${fecha.getMonth() + 1}`.slice(-2);
  let year = fecha.getFullYear();

  if (hora)
    var HORA = [
      ("00" + fecha.getHours()).slice(-2),
      ("00" + fecha.getMinutes()).slice(-2),
    ].join(":");

  return [[day, month, year].join(" "), HORA].filter((v) => !!v).join(", ");
}

export function timeAgo(timestamp, hours = true) {
  // if (timestamp == null) return null;
  let now = Date.now();
  if (!hours) {
    let dateAux = new Date(timestamp);
    dateAux.setHours(0, 0, 0, 0);
    timestamp = dateAux.getTime();
    dateAux = new Date();
    dateAux.setHours(0, 0, 0, 0);
    now = dateAux.getTime();
  }
  const future = timestamp > now;
  const DATE_UNITS = {
    // week: 86400 * 7,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  };

  const getUnitAndValueDate = (secondsElapsed) => {
    secondsElapsed = Math.abs(secondsElapsed);
    for (const [unit, secondsInUnit] of Object.entries(DATE_UNITS)) {
      if (secondsElapsed >= secondsInUnit || unit === "second") {
        const value = Math.floor(secondsElapsed / secondsInUnit) * -1;
        return { value, unit };
      }
    }
  };

  const getTimeAgo = () => {
    const rtf = new Intl.RelativeTimeFormat("es", {
      localeMatcher: "best fit",
      numeric: "auto",
    });

    const { value, unit } = getUnitAndValueDate(secondsElapsed);
    return rtf.format(future ? Math.abs(value) : Math.abs(value) * -1, unit);
  };

  const secondsElapsed = (now - timestamp) / 1000;
  // const secondsElapsed = Date.now() > timestamp ? (Date.now() - timestamp) / 1000 : (timestamp - Date.now()) / 1000

  if (secondsElapsed >= DATE_UNITS.week) {
    return parseDate(timestamp, false, true, true);
  } else {
    const [first, ...rest] = getTimeAgo(timestamp);
    return [first.toUpperCase(), rest.join("")].join("");
  }
}

export function getSituacionIcon(situacion) {
  const status = {
    Trámite: { color: "secondary", icon: "mdi-timer-sand" },
    "Sin enviar": { color: "#6403ff", icon: "mdi-email-remove-outline" },
    "Pendiente de Firma": { color: "#577399", icon: "mdi-draw" },
    "Pendiente de Firma (SMS)": {
      color: "#577399",
      icon: "mdi-message-processing",
    },
    "Pendiente de Firma (Email)": { color: "#577399", icon: "mdi-email-edit" },
    Anulado: { color: "#d00000", icon: "mdi-close-octagon-outline" },
    Eliminado: { color: "error", icon: "mdi-delete" },
    "KO Scoring": { color: "#d00000", icon: "mdi-cancel" },
    NEGACION: { color: "#d00000", icon: "mdi-close-octagon-outline" },
    "Prueba ": { color: "purple", icon: "mdi-test-tube" },
    Prueba: { color: "purple", icon: "mdi-test-tube" },
    Incidencia: { color: "#ff4000", icon: "mdi-alert-circle-outline" },
    "Pendiente Revisión": { color: "purple", icon: "mdi-eye" },
    Borrador: { color: "primary", icon: "mdi-eraser" },
    "Pendiente Activación a Futuro": {
      color: "#ffa600",
      icon: "mdi-clock-time-five-outline",
    },
    "Enviado a verificación": { color: "secondary", icon: "mdi-upload" },
    "Enviado a SIGE": { color: "secondary", icon: "mdi-upload" },
    "Baja por modificación": { color: "#FF3C00", icon: "mdi-pencil-off" },
    Caducado: { color: "#FF3C00", icon: "mdi-timer-sand-empty" },
    Cortado: { color: "#FF3C00", icon: "mdi-content-cut" },
    Firmado: { color: "primary", icon: "mdi-draw" },
    "Inspección Alta": { color: "warning", icon: "mdi-account-tie" },
    "Oferta Enviada": { icon: "mdi-cloud-upload", color: "secondary" },
    "Oferta Pendiente": { icon: "mdi-clock", color: "secondary" },
    "Rechaza Oferta": { icon: "mdi-cancel", color: "error" },
    "Oferta rechazada": { icon: "mdi-cancel", color: "error" },
    "Contrato Firmado": {
      icon: "mdi-file-document-edit",
      color: "secondary",
    },
    "Firma no válida": { icon: "mdi-close", color: "error" },
    "Contrato No Firmado": { icon: "mdi-file-remove", color: "warning" },
    "Generando certificado": {
      icon: "mdi-file-certificate-outline",
      color: "secondary",
    },
    "Pendiente Comercial SW": {
      icon: "mdi-account-clock",
      color: "secondary",
    },
    "Pendiente Validar SW": {
      icon: "mdi-swap-horizontal",
      color: "secondary",
    },
    "Scoring Rechazado": { icon: "mdi-eye-off", color: "error" },
    Scoring: { icon: "mdi-eye", color: "secondary" },
    Contrato: { icon: "mdi-check", color: "success" },
    Activado: { icon: "mdi-check", color: "success" },
    "Trámite/Validado": { icon: "mdi-timer-sand", color: "secondary" },
    Baja: { icon: "mdi-alert-circle-outline", color: "#FF3C00" },
    Oferta: { icon: "mdi-percent", color: "success" },
    Confirmado: { icon: "mdi-file-certificate", color: "success" },
    "No Confirmado": { icon: "mdi-decagram", color: "warning" },
    "No Validado": { icon: "mdi-thumb-down", color: "error" },
    Descartado: { icon: "mdi-thumb-down", color: "error" },
    Pagada: { color: "success", icon: "mdi-check" },
    "Pendiente Pago": { color: "warning", icon: "mdi-currency-eur-off" },
    "Pendiente comprobación": { icon: "mdi-eye", color: "indigo" },

    Remesado: { icon: "mdi-bank", color: "grey" },
    "Cancelado por abono": {
      icon: "mdi-alert-circle-outline",
      color: "#FF3C00",
    },
    Cobrado: { icon: "mdi-cash", color: "success" },
    Fraccionado: { icon: "mdi-division", color: "warning" },
    Impagado: { icon: "mdi-currency-eur-off", color: "error" },
    Pendiente: { icon: "mdi-timer-sand", color: "secondary" },

    "Comprometido Pago": { icon: "mdi-handshake", color: "secondary" },
    "Pago no realizado": { icon: "mdi-heart-broken-outline", color: "#B11E31" },
    "Fraccionado 2 Pagos": { icon: "mdi-division", color: "#FFBE0B" },
    "Fraccionado 3 Pagos": { icon: "mdi-division", color: "#FB5607" },
    "Fraccionado 4 Pagos": { icon: "mdi-division", color: "#cf4300" },
    "Sin Compromiso": { icon: "mdi-close", color: "error" },
    Abonado: { icon: "mdi-cash", color: "success" },
    "Pendiente Giro": { icon: "mdi-arrow-u-down-left", color: "grey" },
    "Pagado Tarjeta": {
      icon: "mdi-credit-card-check",
      color: "success darken-1",
    },
    "Factura errónea": { icon: "mdi-alert", color: "warning" },

    "1ª Carta": { icon: "mdi-email-send", color: "warning" },
    "2ª Carta": { icon: "mdi-email-send", color: "deep-orange" },
    "Carta de Corte": { icon: "mdi-content-cut", color: "error" },
    Cortado: { icon: "mdi-skull", color: "" },

    Abierta: { icon: "mdi-check", color: "success" },
    Cerrada: { icon: "mdi-close", color: "error" },

    Email: { icon: "mdi-email", color: "secondary" },
    Llamada: { icon: "mdi-phone", color: "primary" },
    Comercial: { icon: "mdi-account-tie", color: "warning" },
    Otros: { icon: "mdi-dots-horizontal-circle-outline", color: "light-grey" },

    "Pagos pendientes": { icon: "mdi-currency-eur-off", color: "error" },
    "Pago regularizado": { icon: "mdi-cash", color: "success" },

    Luz: { icon: "mdi-lightning-bolt", color: "warning" },
    Gas: { icon: "mdi-fire", color: "deep-orange" },
    Dual: { icon: "$Dual", color: "deep-orange lighten-1" },
  };

  return status[situacion] || { color: "grey", icon: "mdi-circle" };
}

export function downloadFile(file, open = false) {
  let a = document.createElement("a");
  a.href = URL.createObjectURL(file);
  if (
    open &&
    ![
      "iPad",
      "iPhone",
      "iPod",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad Simulator",
      "Macintosh",
      "MacIntel",
      "MacPPC",
      "Mac68K",
      "Pike v7.6 release 92",
      "Pike v7.8 release 517",
    ].includes(navigator.platform)
  )
    a.target = "_blank";
  else a.download = file.name;
  a.click();
}

export const perColumnFilter = (value, filter, dataType = "text") => {
  if (
    filter === "" ||
    filter === null ||
    filter === undefined ||
    (Array.isArray(filter) && filter.length == 0)
  )
    return true;
  if (filter === "-") return value === null;
  if (value === "" || value === null || value === undefined) return false;

  const filterText = (filter, value) => {
    value = String(value)
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    filter = String(filter)
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    const isOperation = /=|<|>|!/.test(filter[0]);
    if (isOperation) {
      switch (filter[0]) {
        case "!":
          return !value.includes(filter.substring(1));
        case "=":
          return value == filter.substring(1);
        case ">":
          return Number(value) >= Number(filter.substring(1));
        case "<":
          return Number(value) <= Number(filter.substring(1));
        default:
          return false;
      }
    } else {
      return value.includes(filter);
    }
  };
  const filterDate = (filter, value) => {
    filter = filter.map((f) => {
      let temp = new Date(f);
      temp.setHours(0, 0, 0, 0);
      return temp.getTime();
    });
    value = parseDate(value, true);
    let max = Math.max(...filter);
    let min = Math.min(...filter);
    return max >= value && value >= min;
  };
  const filterSelect = (filter, value) => {
    if (filter.length > 1) return filter.includes(value);
    else return filterText("=" + filter[0], value);
  };
  const filterBool = (filter, value) => filter == value;

  switch (dataType) {
    case "select":
      return filterSelect(filter, value);
    case "date":
      return filterDate(filter, value);
    case "bool":
      return filterBool(filter, value);
    case "text":
    default:
      return filterText(filter, value);
  }
};

export function dateToIso(date){
  var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}